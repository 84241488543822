<template>
  <form @submit.prevent="handleLoginJWT">
    <vs-input
      v-model="email"
      v-validate="'required|email|min:3'"
      name="email"
      icon-no-border
      icon="icon icon-user"
      icon-pack="feather"
      label-placeholder="Email"
      class="w-full"
      autocomplete="username"
    />
    <v-error :err="errors" field="email" />

    <vs-input
      v-model="password"
      v-validate="'required|min:8'"
      type="password"
      name="password"
      icon-no-border
      icon="icon icon-lock"
      icon-pack="feather"
      label-placeholder="Password"
      class="w-full mt-6"
      autocomplete="current-password"
    />
    <v-error :err="errors" field="password" />

    <div class="flex flex-wrap justify-between my-5">
      <div class="flex flex-col text-left">
        <router-link to="/forgot-password">Forgot password?</router-link>
        <router-link to="/verify/resend">Cannot verify email?</router-link>
      </div>
    </div>
    <div class="flex flex-wrap justify-between mb-3">
      <vs-button :disabled="!validateForm" @click="handleLoginJWT">
        Login
      </vs-button>
      <input type="submit" style="display: none" />
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex';

import { getMessageFromError } from '@/helpers';
import { AuthAction } from '@/store/actionTypes';

import vError from '@/components/VError';

export default {
  components: {
    vError,
  },
  data: () => ({
    email: '',
    password: '',
  }),
  computed: {
    validateForm() {
      return !this.errors.any() && this.email !== '' && this.password !== '';
    },
  },
  methods: {
    ...mapActions('auth', [AuthAction.loginJWT]),

    checkLogin() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.$vs.loading.close();

        this.notifyWarning('Login Attempt', 'You are already logged in!');
      }

      return true;
    },
    async handleLoginJWT() {
      if (!this.checkLogin()) {
        return;
      }

      this.$vs.loading();

      try {
        await this.loginJWT({
          userDetails: {
            email: this.email,
            password: this.password,
          },
        });
      } catch (error) {
        this.notifyError(error, getMessageFromError(error));
      }

      this.$vs.loading.close();
    },
    registerUser() {
      if (!this.checkLogin()) {
        return;
      }

      this.$router.push({ name: 'register' }).catch();
    },
  },
};
</script>
