<template>
  <span v-show="err.has(field)" class="text-danger text-sm">
    {{ err.first(field) }}
  </span>
</template>

<script>
export default {
  name: 'VError',
  props: {
    err: {
      type: Object,
      default: () => {},
      require: true,
    },
    field: {
      type: String,
      default: '',
      require: true,
    },
  },
};
</script>
